export type BinderFragment<T> = (params: T) => void

/**
 * A class to hold informations in order to use hook in global functions
 */
export class Binder<T extends {}> {
    public readonly funcs: BinderFragment<T>[] = [];
    private params?: T;
    constructor() {

    }

    /**
     * Register callback to be executed when the context is ready
     * @param func 
     */
    register(func: BinderFragment<T>) {
        this.funcs.push(func);
    }

    /**
     * Initliazes the binder with all the required fields
     * @param params 
     */
    init(params: T) {
        this.params = params;
    }

    /**
     * Executes all the binder with the current context
     */
    bind() {
        const params = this.params;
        if (params) {
            this.funcs.forEach(f => {
                console.debug('binding ', f);
                f(params);
            });
            console.debug('bound globals to context', params, this.funcs);
        } else {
            throw new Error('Not initialized');
        }

    }
}
