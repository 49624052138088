import CssBaseline from "@material-ui/core/CssBaseline";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Routes from "./components/common/router/PreRouter";
import "./index.css";
import keycloak from "./keycloak";
import React from "react";

export const hjid = 2956882;
export const hjsv = 6;

const App = () => {
  return (
    <RecoilRoot>
      <>
        <CssBaseline />
        <BrowserRouter>
          <ReactKeycloakProvider authClient={keycloak}>
            <Routes />
          </ReactKeycloakProvider>
        </BrowserRouter>
      </>
    </RecoilRoot>
  );
};
export default App;
