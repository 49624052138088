import { useCallback } from "react";
import { useHistory } from "react-router";
import { useAlert } from "../components/common/Alert/atom";
import { useSnackbar } from "../components/common/Snackbar/atom";
import { Binder } from "../utils/binding";

type KiwiBinder = {
  snackbar: ReturnType<typeof useSnackbar>;
  alert: ReturnType<typeof useAlert>;
  history: ReturnType<typeof useHistory>;
};

export const binder = new Binder<KiwiBinder>();

export function useBindGlobal() {
  const snackbar = useSnackbar();
  const alert = useAlert();
  const history = useHistory();

  const bind = useCallback(() => {
    binder.init({ snackbar, alert, history });
    binder.bind();
  }, [snackbar, useAlert, history]);

  return bind;
}
