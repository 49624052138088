import keycloak from "../keycloak";
import kiwiQueryParamSerializer from "../utils/kiwiSerializers";
import { uploadForm } from "../utils/useUpload";
import { Page } from "../utils/utils";
import { API, HTTPRequestError, KiwiQueryBodyPrepartor } from "./apiUtils";
import SimpleCache, { LocalStoreObjectDataStore, NoopCache } from "./utils/cacheUtils";
import { KeycloakSecurityProvider } from "./securityUtils";

export { HTTPRequestError };

const cacheKey = "KX_CACHE"

const apiUrl = process.env.REACT_APP_API_URL || "/api";

export const api = new API(
  apiUrl,
  new KeycloakSecurityProvider(keycloak),
  new NoopCache(),
  // new SimpleCache(new LocalStoreObjectDataStore(cacheKey)),
  new KiwiQueryBodyPrepartor()
);

export const { get, put, del, patch, post } = api;

export function getPaged<T, V extends string[] | number[]>(
  url: string,
  page: number,
  pageSize: number,
  params: { [param: string]: string | V },
) {
  const s = kiwiQueryParamSerializer.serialize(params, false);
  return api.get<Page<T>>(`${url}?page=${page}&size=${pageSize}${s}`);
}

export function getUploadForm(url: string) {
  return api.get<uploadForm>(`${url}`);
}

export function postUploadForm(url: string) {
  return api.post<uploadForm>(`${url}`, {});
}

export function putUploadForm(url: string) {
  return api.put<uploadForm>(`${url}`);
}

