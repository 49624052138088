import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import quotes from "../../quotes.json";
import { NameProvider } from "../atomUtils";

type QuoteAtomState = {
  at: Date | undefined;
  text: string | undefined;
};

/**
 * 10 Seconds
 */
export const timeBetweenQuotes = 10 * 1000;

const quoteNameProvider = new NameProvider("quote");

/**
 *
 * @param quotes
 * @param delay Delay should be in milliseconds
 * @returns
 */
function makeQuoteProvider(quotes: string[], delay: number = timeBetweenQuotes) {
  const quoteAtom = atom<QuoteAtomState>({
    key: quoteNameProvider.next(),
    default: {
      at: undefined,
      text: undefined,
    },
  });
  const useQuote = () => {
    let [{ text, at }, setState] = useRecoilState(quoteAtom);
    const now = new Date();
    if (!at || !text || ((now.getTime() - at.getTime()) >= delay)) {
      text = quotes[Math.floor(Math.random() * quotes.length)];
      setState({ text, at: now });
    }
    return text;
  };
  return useQuote;
}

export const useDefaultQuotes = makeQuoteProvider(quotes);
