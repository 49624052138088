import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";

export class NameProvider {
  current: number;
  public readonly prefix: string;
  constructor(prefix: string) {
    this.current = 0;
    this.prefix = prefix;
  }
  next() {
    return `${this.prefix}${this.current++}`;
  }
}

const getterFactory = () => {
  const nameProvider = new NameProvider("");
  function makeOnceGetter<T>(getter: () => Promise<T>) {
    const key = nameProvider.next();
    const a = atom<T | undefined>({
      key,
      default: undefined,
    });

    const loadingAtom = atom({
      key: `${key}Loading`,
      default: {
        loading: false,
        loaded: false,
      },
    });

    function useIt(lazy: boolean = false): [T | undefined, boolean, (force?: boolean) => void] {
      const [it, setIt] = useRecoilState(a);
      const [state, setState] = useRecoilState(loadingAtom);
      const { loaded, loading } = state;

      const ensure = useCallback(
        (force: boolean = false) => {
          if (loading) {
            return;
          }
          if (!loaded || force) {
            setState(e => ({ ...e, loading: true }));
            getter()
              .then(c => {
                setState({ loaded: true, loading: false });
                setIt(c);
              })
              .catch(() => {
                setState({ loaded: false, loading: false });
              });
          }
        },
        [setIt, loading, setState],
      );

      useEffect(() => {
        if (!lazy) {
          ensure();
        }
      }, [ensure, lazy]);

      return [it, loading, ensure];
    }
    return useIt;
  }
  return makeOnceGetter;
};

export const onceGetter = getterFactory();
