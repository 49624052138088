import { useCallback } from "react";
import { atom, SetterOrUpdater, useSetRecoilState } from "recoil";

type AlertState =
  | {
      message: string;
      validateAction?: (() => void) | (() => Promise<any>);
      cancelAction?: (() => void) | (() => Promise<any>);
    }
  | undefined;

export const alertAtom = atom<AlertState>({
  key: "alert",
  default: undefined,
});

export const clearAlert = (setter: SetterOrUpdater<AlertState>) => setter(undefined);

export const useAlert = () => {
  const setter = useSetRecoilState(alertAtom);
  const f = useCallback(
    (message: string, validateAction: () => void, cancelAction?: () => void) => {
      setter({ message, validateAction, cancelAction });
    },
    [setter],
  );
  return f;
};
