import { makeStyles } from "@material-ui/core";
import { useKeycloak } from '@react-keycloak/web';
import React, { Suspense, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { binder, useBindGlobal } from '../../../api/globalBinder';
import { ws } from '../../../api/ws';
import { RandomTextLoading } from '../LoadingComponent/LoadingComponent';

const AppRouter = lazy(() => import('./Router'));

const useStyles = makeStyles({
  div: {
    display: "flex",
    height: "100vh",
    alignItems: "center",
  },
});

export default function PreRouter() {
  const { initialized, keycloak } = useKeycloak();
  const classes = useStyles();
  const history = useHistory();

  // binding global
  // here in order to use the snackbar in a global ws object instead of using everytime
  const bind = useBindGlobal();

  React.useEffect(() => {
    binder.register(({ snackbar }) => {
      ws.snackbar = snackbar;
    });
    bind();
  }, [bind]);

  if (!initialized) {
    return (
      <div className={classes.div}>
        <RandomTextLoading style={{ height: "100vh" }} loading />;
      </div>
    );
  }
  if (!keycloak.authenticated) {
    history.push("/");
  }
  return (
    <Suspense
      fallback={
        <div className={classes.div}>
          <RandomTextLoading loading />
        </div>
      }
    >
      <AppRouter />
    </Suspense>
  )
}
